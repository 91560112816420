.App {
  text-align: center;
}

.App-logo {
  height: 70px;
  pointer-events: none;
  padding: 10px;
  padding-top: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 700;
  outline: none;
  padding: 16px 22px;
  background-color: #fff;
  color: #2caf82;
  cursor: pointer;
}

.App-link[disabled],
.App-link[disabled]:hover {
  background-color: #ccc;
  color: #777;
  cursor: not-allowed;
}

.px20 {
  width: 40px;
  display: inline-block;
}

.App-link:hover {
  background-color: #2caf82;
  color: #fff;
}

p {
  font-size: 16px;
}

.text-lg {
  font-size: 30px;
}

.text-para {
  font-size: 16px;
  max-width: 500px;
  line-height: 1.5;
}
.maxw-500 {
  max-width: 500px;
  width: 100%;
}
.text-para code {
  background: #eee;
  padding: 2px;
  color: black;
  margin-left: 4px;
  border-radius: 3px;
}
