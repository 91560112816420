@keyframes moveInButton {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
.Payment {
  text-align: center;
  padding: 40px 0;
  max-width: 550px;
  margin: auto;
}
.Payment__Message__Success {
  color: #9abc66;
}
.Payment__Message__Failed {
  color: #ff6384;
}
.Payment i {
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.Payment .spinner {
  position: relative;
  top: calc(50% - 1rem);
}
.Payment__Card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 4px 8px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
.Payment__Card__Message {
  color: #404f5e;
  font-family: "soletolight";
  font-size: 20px;
  margin: 0;
}
.Payment__Card__Footer {
  font-size: 13px;
  position: relative;
  bottom: -60px;
}
.Payment__Icon {
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #eee;
  margin: 0 auto;
}
.Payment__Icon__Success {
  color: #9abc66;
}
.Payment__Icon__Failed {
  color: #ff6384;
}
.Payment .btn,
.Payment .btn:link,
.Payment .btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.Payment .btn:hover,
.Payment .btn:focus {
  outline: none;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.Payment .btn:hover::after,
.Payment .btn:focus::after {
  transform: scaleY(1.3) scaleX(1.6);
  opacity: 0;
}
.Payment .btn:active {
  outline: none;
  transform: translateY(1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.Payment .btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}
.Payment .btn--white {
  background-color: white;
  color: #777;
  padding: 15px 40px;
  text-align: center;
}
.Payment .btn--white::after {
  background-color: white;
}
.Payment .btn--green {
  background-color: var(--ion-color-primary, #1de9b6);
  color: white;
  padding: 15px 30px;
  text-align: center;
}
.Payment .btn--green::after {
  background-color: var(--ion-color-primary, #1de9b6);
}
.Payment .btn--animated {
  animation: moveInButton 0.5s ease-out 1s;
  animation-fill-mode: backwards;
}
